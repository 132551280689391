/**********colors*********/
$white : #ffffff;
$black: #1e1e1e;
$grey: #8c9aad;
$lightGrey: #c0cad7;
$primary-lightGrey: #c0cad74c;
$charcoal-grey: #3c434d;
$manhattan: #F2B999;
$bostonBlue: #3391AA;
$burningOrange: #ff7841;
$shadowGreen: #A3CAB8;
$surf: #BDD9CC;
$guardsmanRed: #c10202;
$valentino: #3c0c44;
$casper: #a3bcca;
$pomegranate: #ef3923;
$yellowOrange: #ffb441;
$jungleGreen: #30b567;
$gallery: #f0f0f0;
$bgVerdate: #a3cab8;
$bgGreenBlue: #a3bcca;
$bgManhattan: #F2B999;
$disabled: #f0f0f0;

/**********Font-family*********/
$font-normal: 'Ubuntu-Regular' ;
$font-bold: 'Ubuntu-Bold';
$font-medium: 'Ubuntu-Medium';

/***z-index***/
$header-index: 1030;
$sidebar-index: 1040;
$modal-index: 1050;
