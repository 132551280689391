@import "./variables";

.text-light-grey {
  color: $lightGrey;
}

.text-grey {
  color: $grey;
}

.text-charcoal-grey {
  color: $charcoal-grey;
}

.text-black {
  color: $black;
}

.text-pomegranate {
  color: $pomegranate;
}

.text-valentino {
  color: $valentino;
}

.text-burningOrange {
  color: $burningOrange;
}

.text-guardsmanRed {
  color: $guardsmanRed
}
.bg-black {
  background-color: $black;
}
.bg-gallery {
  background-color: $gallery;
}

.bg-orange {
    background: $yellowOrange;
}

.bg-primary-lightGrey {
  background-color: $primary-lightGrey;
}

.bg-pomegranate {
  background-color: $pomegranate;
}

.bg-verdate {
  background-color: $bgVerdate;
}
 .bg-greenBlue {
   background-color: $bgGreenBlue;
 }

.bg-bostonBlue {
  background-color: $bostonBlue;
}

.bg-casper {
  background-color: $casper;
}

.bg-yellowOrange {
  background-color: $yellowOrange;
}

.bg-shadowGreen {
  background-color: $shadowGreen;
}
